import { useAuth } from 'contexts/AuthContext'
import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import Loader from 'react-spinners/BeatLoader'
import {
  AdvertiseBlock,
  ColumnsBlock,
  ContentBlock,
  CtaBlock,
  CtaiconsBlock,
  CtaTwoColsBlock,
  FeaturesBlock,
  FindBlock,
  ImageBlock,
  PostsBlock,
  ProductsBlock,
  PromoBannerBlock,
  ResourcesBlock,
  SearchBlock,
  SeminarBlock,
  SignInBlock,
  TwoColsSimpleBlock,
  WarrantyTermsBlock,
} from 'starterComponents'

const Page = (props) => {
  const {
    data: {
      page,
      page: {
        flexLayouts: { flexibleLayouts },
        memberPage: { needsMembership },
      },
    },
  } = props

  const { currentUser, loading } = useAuth()

  useEffect(() => {
    if (needsMembership) {
      if (!loading && currentUser === null) {
        navigate('/sign-in')
      }
    }
  }, [currentUser, loading, needsMembership])

  const PageContent = ({ needsMembership, currentUser, loading }) => {
    // Show loader page when:
    // - User still loaded (1st condition)
    // - Data is loaded but not registered user (2nd condition)
    const showLoaderPage =
      (needsMembership && loading) ||
      (needsMembership && !loading && !currentUser)

    if (showLoaderPage) {
      return (
        <div className="flex items-center justify-center width-full height-full py-96">
          <div className="flex flex-col">
            <div className="flex justify-center mb-10">
              <Loader size={20} margin={5} color="darkBlue" />
            </div>
            <h2 className="text-center uppercase text-l">
              You must be registered to view this page
            </h2>
          </div>
        </div>
      )
    }
    return (
      <>
        {flexibleLayouts?.map((block, index) => {
          switch (block.__typename) {
            case 'WpPage_Flexlayouts_FlexibleLayouts_ColumnsBlock':
              return <ColumnsBlock key={index} {...block} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_ContentBlock':
              return <ContentBlock key={block.__typename} {...block} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_FeaturesBlock':
              return <FeaturesBlock key={index} {...block} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_PromoBannerBlock':
              return <PromoBannerBlock key={index} {...block} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_SearchBlock':
              return <SearchBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_PostsBlock':
              return <PostsBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_ProductsBlock':
              return <ProductsBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_ResourcesBlock':
              return <ResourcesBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_AdvertiseBlock':
              return <AdvertiseBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_CtaBlock':
              return <CtaBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_FindBlock':
              return <FindBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_CtaiconsBlock':
              return <CtaiconsBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_CtaTwoColsBlock':
              return <CtaTwoColsBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_ImageBlock':
              return <ImageBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_TwoColsSimpleBlock':
              return <TwoColsSimpleBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_WarrantyTermsBlock':
              return <WarrantyTermsBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_SignInBlock':
              return <SignInBlock {...block} key={index} />
            case 'WpPage_Flexlayouts_FlexibleLayouts_SeminarBlock':
              return <SeminarBlock {...block} key={index} />
              {
                /* case 'WpPage_Flexlayouts_FlexibleLayouts_PublicationCorrectionsBlock':
              return <PublicationCorrectionsBlock {...block} key={index} /> */
              }
            default:
              return ''
          }
        })}
      </>
    )
  }

  return (
    <PageContent
      {...props}
      currentUser={currentUser}
      needsMembership={needsMembership}
      loading={loading}
      page={page}
      type="page"
    />
  )
}

export default Page

export const pageQuery = graphql`
  query WpPageDefaultTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      uri
      slug
      isFrontPage
      flexLayouts {
        flexibleLayouts {
          __typename
          ...contentBlockFragment
          ...imageBlockFragment
          ...featuresBlockFragment
          ...promoBannerBlockFragment
          ...searchBlockFragment
          ...columnsBlockFragment
          ...postsBlockFragment
          ...productsBlockFragment
          ...resourcesBlockFragment
          ...advertiseBlockFragment
          ...ctaBlockFragment
          ...findBlockFragment
          ...ctaiconsBlockFragment
          ...ctaTwoColsBlockFragment
          ...twoColsSimpleBlockFragment
          ...warrantyTermsBlockFragment
          ...signInBlockFragment
          ...seminarBlockFragment
        }
      }
      memberPage {
        needsMembership
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }
        }
      }
      template {
        templateName
      }
    }
  }
`
